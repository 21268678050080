<template lang="pug">
div
  //Блок информации об отчете
  v-row(v-if="!show_report")
    v-col(cols="12") Отчёт отсутствует
  v-row(v-else)
    v-col(cols="12")
      v-data-table(:headers="headers" :items="report" hide-default-footer class="elevation-1")
        template(v-slot:item.date="{ item }")
          v-edit-dialog(v-if="!isCustomer && isEdit" :return-value="item.date" large @save="saveDate(item)" @open="openDate(item)") {{ reportDate }}
                v-icon mdi-pencil
                template(v-slot:input)
                  v-menu(v-model="menu" transition="scale-transition" offset-y max-width="290px" min-width="auto")
                    template(v-slot:activator="{ on, attrs }")
                      v-text-field(v-model="computedDateFormatted"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on")
                    v-date-picker(v-model="date" no-title @input="menu = false" :max="new Date().toISOString().substr(0, 10)" first-day-of-week="1")
          span(v-else) {{ reportDate }}
    //Блок мотивационного отчёта
    v-col(cols="12")
      v-data-table(id="photos-container" :headers="dataHeaders" :items="reportdata" :footer-props="footer_props" class="elevation-1")
        template(v-slot:item="{ item }")
          tr(style="background: #87CEFA")
            td(v-for="(header, i) in dataHeaders" :key="i")
              template(v-if="header.value == 'promo'")
                v-icon(v-if="item[header.value]" color="#ff4081") mdi-bookmark
              template(v-else-if="header.value == 'comment'")
                v-edit-dialog(:return-value="item[header.value]" large  @save="saveComment(item)" @open="openComment(item)") {{ item[header.value] }}
                  v-icon mdi-pencil
                  template(v-slot:input)
                    v-text-field(v-model="itemComment")
              template(v-else-if="header.value != 'action'") {{ header.presense && item[header.value] == null ? header.def : item[header.value] }}
          tr
            td
              div(style="display: flex; align-items: center")
                template ОМП
                Photo.ml-2(:item="item", type="1" reportType="motivational"  photoType="omp" @func="callFunc")
            td 
              v-row
                Photos(:uuid="id" :photos="item.photos.filter(x => !x.dmp)" :item="item" :id="item.id" type="1" reportType="motivational" photoType="omp" @func="callFunc" )
          tr
            td
              div(style="display: flex; align-items: center")
                template ДМП
                Photo.ml-2(:item="item", type="1" reportType="motivational"  photoType="dmp" @func="callFunc")
            td
              v-row
                Photos(:uuid="id" :photos="item.photos.filter(x => x.dmp)" :item="item" :id="item.id" type="1" reportType="motivational" photoType="dmp" @func="callFunc")    
      v-snackbar(color="green" v-model="snackbar.open" :timeout="snackbar.timeout") {{ snackbar.text }}
      v-snackbar(color="red" v-model="snackbar.error" :timeout="snackbar.timeout") {{ snackbar.text }}
</template>


<script>
import { motivationalReportService } from '@/_services'
import { mapMutations, mapGetters } from "vuex";
import Export from './Export'
import Photos from './Photos'
import Photo from './Photo'

export default {
  components: {
    Export,
    Photos,
    Photo
  },

  props: ['id', 'network', 'reportDate'],
  
  data: () => ({
    show_report: false,
    snackbar: {
      text: '',
      open: false,
      error: false,
      timeout: 2000
    },
    headers: [
      { text: 'Время', value: 'date' },
      { text: 'Проект', value: 'project' },
      { text: 'Ретейлер', value: 'retailer' },
      { text: 'Адрес', value: 'address' },
    ],
    report: [],
    fields: [],
    headersdata: [],
    reportdata: [],
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    footer_props: {
      'items-per-page-options': [25, 50, 100]
    },
    succesChange: false,
    isEdit: false,
    itemComment: '',
  }),

  mounted() {
    this.loadReport()
  },

  computed: {
    ...mapGetters("authentication", ["checkRole"]),
    isCustomer() {
      return this.checkRole(0)
    },

    isCoordinator() {
      return this.checkRole(2)
    },

    dataHeaders() {
      let list = this.headersdata
      return list
    },

    computedDateFormatted() {
      console.log(this.date);
      return this.dateFormat(new Date(Date.parse(this.date)))
    },
  },

  watch: {
    project: function(val) {
        this.loadNetworks(val)
    }
  },

  methods: {
    ...mapMutations(["setAppBackLink"]),
    loadReport() {
      motivationalReportService.getMotivationalReport(this.id, new Date(this.reportDate)).then(data => {
        let date = new Date(Date.parse(this.reportDate))
        this.report = []
        console.log(data);
        this.report.push(data)
        this.report[0].date = this.dateFormat(date)
        this.date = date.toISOString().substr(0, 10)
        this.headersdata = [
          { text: 'Вид ДМП', value: 'dmpCategory', sortable: false },
          { text: 'Комментарий', value: 'comment', sortable: false }
        ]
        this.reportdata = []
        for(let i = 0, rd = data.motivationalDmps; i < rd.length;i++) {
          if(data.motivationalDmps[i].motivationalDmpReports.length > 0){
            for(let j = 0, rd = data.motivationalDmps[i].motivationalDmpReports; j < rd.length;j++){
              this.reportdata.push(this.parseData(rd[j], data.motivationalDmps[i].comment))
            }
          }else {
            this.reportdata.push(this.parseData(data.motivationalDmps[i], data.motivationalDmps[i].comment))
          } 
          
        }
        console.log(this.reportdata);
        this.isEdit = data.edit;
        this.show_report = true;
      })
    },

    filterByProductName(field) {
      return (a, b) => a[field] > b[field] ? 1 : -1;
    },

    del() {
      this.dialogDelete = true
    },

    saveDate(d) {
      motivationalReportService.editReportDate(d.id, new Date(Date.parse(this.reportDate)).toISOString().substr(0, 10), this.date).then(() => {
        this.$router.push(`/report/motivationalReport/${this.id}/${this.date}/`)
        this.alert('Дата изменена');
      }).catch(error => this.errorAlert(error.response.data.message))
    },

    openDate(d) {
      this.date = new Date(Date.parse(this.reportDate)).toISOString().substr(0, 10) 
    },

    dateFormat(date) {
      return `${date.getDate() < 10? '0' : ''}${date.getDate()}.${date.getMonth() + 1 < 10? '0' : ''}${date.getMonth() + 1}.${date.getFullYear()}`
    },

    callFunc(func, item, type, param_1, photo, res) {
      func(item.id, photo).then(res).catch(error => {
        console.log(error)
      }).finally(() => {
        if (type == 0) this.report = this.report.slice(0);
        else this.reportdata = this.reportdata.slice(0);
      })
    },

    parseData(data, comment) {
      let obj = { id: data.id, dmpCategory: data.dmpCategory, comment: comment, photos: data.reportPhotos ? data.reportPhotos : [] }
      return obj
    },

    alert(text) {
      this.snackbar.text = text;
      this.snackbar.open = true
    },

    errorAlert(text) {
      this.snackbar.text = text;
      this.snackbar.error = true
    },

    saveComment(item) {
      console.log(this.itemComment);
      motivationalReportService.editDmpComment(item.id, this.itemComment).then(() => {
        item.comment = this.itemComment
        this.alert('Комментарий изменён');
      })
    },

    openComment(item) {
      this.itemComment = item.comment;
    },
  }
}
</script>

<style>
    .text-nowrap {
      white-space: nowrap !important;
    }
    .v-btn .v-input__prepend-outer {
      margin-top: 4px;
      margin-right: 0;
    }
</style>

